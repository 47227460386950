<template>
  <div v-if="isMounted">
    <big-title>Planes de inversión</big-title>
    <!-- PANEL DE CUMPLIMIENTO -->
    <compliance-banner @loadedRequirements="loadedRequirements" />

    <div class="vx-row">
      <div class="vx-col lg:w-1/2 w-full">
        <vx-card class="mt-5">
          <div class="flex justify-between flex-wrap small-flex mt-1">
            <h4 class="bold">Inversión automática</h4>
            <vs-switch color="success" v-model="autoInvestPayments"
              @click.stop="confirmSwitchInvestmentPlanOption('autoinvest_applicant_payments', !autoInvestPayments)" />
          </div>
          <div class="vx-row">
            <div class="vx-col w-full">
              <p class="mt-5">
                Invierte tu dinero
                automáticamente y crece
                de manera exponencial.
              </p>
              <vs-collapse class="mt-1">
                <vs-collapse-item v-if="carouselInv.length > 0">
                  <div slot="header">
                    <h4 class="bold">¿Cómo funciona?</h4>
                  </div>
                  <full-image-carousel :images="carouselInv" v-if="carouselInv.length > 0" />
                </vs-collapse-item>
              </vs-collapse>
            </div>
          </div>
        </vx-card>
      </div>
      <div class="vx-col lg:w-1/2 w-full">
        <vx-card class="mt-5">
          <div class="flex justify-between flex-wrap small-flex">
            <h4 class="bold">Abono automático</h4>
            <!-- <vs-chip color="dark">Próximamente</vs-chip> -->
            <vs-switch v-if="monthlyAutoInvest" color="success" v-model="directBillingisActive"
              @click.stop="confirmSwitchInvestmentPlanOption('autoinvest_deposits', !directBillingisActive)" />
            <vs-button v-else size="small" type="border" @click="popUpmonthlyAutoInvestActive = true"
              color="success">Activar</vs-button>
          </div>
          <div class="vx-row">
            <div class="vx-col w-full">
              <template v-if="monthlyAutoInvest">
                <vs-list>
                  <vs-list-header />
                  <vs-list-item :title="'Monto: ' + explicitMoneyFormat(investorPlan.card_amount, 0)" />
                  <vs-list-item :title="'Día del mes para el cargo: ' + investorPlan.monthly_day.toString()" />
                  <vs-list-item :title="activeBankClabe" />
                </vs-list>
                <vs-button v-if="directBillingisActive" size="small" type="border"
                  @click="popUpmonthlyAutoInvestActive = true" class="mt-4 ml-3" color="dark">Personalizar</vs-button>
              </template>

              <p v-else class="mt-5">
                Escoge el monto y el día que
                deseas abonar a tu cuenta
                automáticamente.
              </p>
              <vs-collapse>
                <vs-collapse-item v-if="carouselAbono.length > 0">
                  <div slot="header">
                    <h4 class="bold">¿Cómo funciona?</h4>
                  </div>
                  <full-image-carousel :images="carouselAbono" v-if="carouselAbono.length > 0" />
                </vs-collapse-item>
              </vs-collapse>
            </div>
          </div>
        </vx-card>
      </div>
    </div>

    <!-- MODAL PARA SELECCIONAR DATOS DEL ABONO RECURRENTE -->
    <vs-popup :active.sync="popUpmonthlyAutoInvestActive" title="Planes de inversión" class="header-primary">
      <div v-if="!showNewAccountForm" class="vx-row mt-5 mb-base">
        <div class="vx-col w-full mb-base">
          <h4 class="bold">Activa tu abono automático</h4>
        </div>
        <div class="vx-col w-1/2 mb-5">
          <label class="bold">Monto mensual</label>
          <vs-input class="investment-input w-full" type="text" v-model="accountInvestmentMoney" v-money="money"
            name="accountInvestmentMoney" v-validate="'required'" :min="500" icon-pack="feather" icon="icon-dollar-sign"
            autocomplete="off" placeholder="mín. $500" />

        </div>
        <div class="vx-col w-1/2 mb-5">
          <label class="bold">Día del mes</label>
          <v-select name="processingDay" class="vs-custom w-full" v-model.lazy="processingDay" v-validate="'required'"
            :options="daysCollection" :reduce="item => item.value" :clearable="false" :searchable="false"
            placeholder="Selecciona">
            <div slot="no-options">No hay coincidencias</div>
          </v-select>
        </div>

        <div class="vx-col sm:w-full w-full mt-3 mb-5">
          <label class="bold">Cuenta</label>
          <template v-if="formattedAccounts.length > 0">
            <div class="flex justify-left items-center">
              <vx-input-group class="w-full">
                <template slot="prepend">
                  <div class="prepend-text bg-dark">
                    <span class="bold" v-if="selectedAccount">{{ selectedAccount.bank_name }}</span>
                  </div>
                </template>
                <v-select name="accountIdSelected" class="vs-custom w-full" v-model.lazy="accountIdSelected"
                  v-validate="'required'" :options="formattedAccounts" :reduce="item => item.value" :clearable="false"
                  :searchable="false" placeholder="Selecciona">
                  <div slot="no-options">No hay coincidencias</div>
                </v-select>
              </vx-input-group>
            </div>
            <div v-if="selectedAccount && !selectedAccount.direct_billing_acceptance"
              class="flex justify-left items-center mt-base ml-3">
              <feather-icon icon="AlertTriangleIcon" svgClasses="w-6 h-6 text-dark" />
              <b class="ml-2">Por el momento no contamos con un convenio con esa institución financiera. Prueba con otra
                cuenta o <a href="#" class="underline bold black" @click="showNewAccountForm = true">registra una cuenta
                  adicional.</a></b>
            </div>
          </template>
          <template v-else>
            <div class="flex justify-left items-center mt-3 ml-3">
              <feather-icon icon="AlertTriangleIcon" svgClasses="w-6 h-6 text-dark" />
              <b class="ml-2">Aún no tienes cuentas bancarias, <a href="#" class="underline bold black"
                  @click="showNewAccountForm = true">registra una cuenta.</a></b>
            </div>
          </template>
        </div>
        <div class="vx-col w-full mb-5">
          <vs-button :disabled="!canActivateMonthlyAutoInvest" @click="saveMonthlyAutoInvestPrefs" class="mt-5"
            color="dark">Activar</vs-button>
          <p class="mt-5">Al hacer clic en Activar estoy de acuerdo con el <a href="#" class="underline bold black"
              @click="popUpDirectBillingAcceptanceContract = true">consentimiento de pago.</a></p>
        </div>
      </div>
      <div v-else class="vx-row mb-base">
        <div class="vx-col w-full">
          <new-user-clabe v-if="isMounted" @on-back="onBack" @on-saved="onBankAccountSaved" />
        </div>
      </div>

      <!-- Popup para ver consentimiento de pago -->
      <vs-popup :active.sync="popUpDirectBillingAcceptanceContract" title="Consentimiento de pago"
        class="extended-popup header-primary">
        <latest-direct-billing-acceptance-contract v-if="selectedAccount" :bank-account="selectedAccount"
          user-role="investor" />
      </vs-popup>
    </vs-popup>

    <!-- DISCLAIMER INVERSION AUTOMATICA -->
    <automatic-investment-disclaimer />

    <!-- Componente para obtener la geolocalización -->
    <rg-geolocator ref="rgGeolocator" />
  </div>
</template>

<script>
import formatHelper from "@mixins/formatHelper";
import ComplianceBanner from "@components/compliance/ComplianceBanner";
import AutomaticInvestmentDisclaimer from "@components/investor/widgets/AutomaticInvestmentDisclaimer";
import FullImageCarousel from "@components/shared/FullImageCarousel";
import { VMoney } from 'v-money';
import currencyHelper from "@mixins/currencyHelper";
import { BankAccountStatus } from "@/bl/BankAccountStatus";
import LatestDirectBillingAcceptanceContract from "@views/share/contracts/DirectBillingContract/LatestDirectBillingAcceptanceContract.vue";
import NewUserClabe from "@components/profile_shared/NewUserClabe";

export default {
  name: "InvestmentsPlansMainPageV2",
  components: {
    ComplianceBanner,
    AutomaticInvestmentDisclaimer,
    FullImageCarousel,
    LatestDirectBillingAcceptanceContract,
    NewUserClabe
  },
  mixins: [formatHelper, currencyHelper],
  directives: { money: VMoney },
  data() {
    return {
      carouselInv: [
        {
          index: 0,
          url: "https://cdn.redgirasol.com/investors/inv_auto_1.png",
          filename: ""
        },
        {
          index: 1,
          url: "https://cdn.redgirasol.com/investors/inv_auto_2.png",
          filename: ""
        },
        {
          index: 2,
          url: "https://cdn.redgirasol.com/investors/inv_auto_3.png",
          filename: ""
        },
      ],
      carouselAbono: [
        {
          index: 0,
          url: "https://cdn.redgirasol.com/investors/abono_auto_1.png",
          filename: ""
        },
        {
          index: 1,
          url: "https://cdn.redgirasol.com/investors/abono_auto_2.png",
          filename: ""
        },
        // {
        //   index: 2,
        //   url: "https://cdn.redgirasol.com/investors/abono_auto_3.png",
        //   filename: ""
        // },
      ],
      popUpmonthlyAutoInvestActive: false,
      money: {
        decimal: '.',
        thousands: ',',
        prefix: '',
        suffix: '',
        precision: 2,
        masked: false
      },
      accountInvestmentMoney: this.currencyFormat(500),
      processingDay: null,
      daysCollection: [],
      userAccounts: [],
      formattedAccounts: [],
      accountRowSelected: null,
      accountIdSelected: null,
      mexicoCountryId: 700,
      showNewAccountForm: false,
      popUpDirectBillingAcceptanceContract: false,
      contractInvestorDirectBilling: 19,
      contractCancelDirectBillingType: 20,
      directBillingisActive: true,
      isMounted: false,
      autoInvestPayments: false,
      monthlyAutoInvest: false,
      investorPlan: {
        id: null,
        card_id: null,
        card_amount: null,
        reinvestment_percentage: null,
        qualifications: null,
        monthly_day: null,
        available_money: null,
        retry: null,
        min_autoinvest_project_amount_mxn: null,
        max_autoinvest_project_amount_mxn: null,
        autoinvest_applicant_payments: null,
        autoinvest_deposits: null,
        suspendedDirectBillingBankAccount: null,
      },
      activeBankClabe: null,
      switchPlanOption: null,
      switchPlanValue: null,
      mainComplianceRequirements: 0,
    }
  },
  computed: {
    selectedAccount() {
      return this.formattedAccounts.find((f) => f.id == this.accountIdSelected);
    },
    canActivateMonthlyAutoInvest() {
      let amount = this.unMoneyFormat(this.accountInvestmentMoney);
      if (amount < 500) {
        return false;
      }
      if (this.selectedAccount && !this.selectedAccount.direct_billing_acceptance) {
        return false;
      }
      if (this.processingDay === null) {
        this.errorMssg = "Debes seleccionar un día para el cargo.";
        return false;
      }
      return true;
    }
  },
  async beforeMount() {
    this.setCollections();
    await this.getUserAccounts();
    await this.getLatestInvestorDirectBilling();
    this.showLoading(false);
    this.$refs.rgGeolocator.askForGeolocation();
  },
  async mounted() {
    this.showLoading(true);
    this.isMounted = false;
    await this.loadPrefs();
    this.isMounted = true;
    this.showLoading(false);
  },
  methods: {
    async loadPrefs() {
      await this.getPlansInfo();
      await this.setDefaults();
    },
    setCollections() {
      let c = [];
      c.push({ value: 7, label: 7 });
      c.push({ value: 14, label: 14 });
      c.push({ value: 21, label: 21 });
      c.push({ value: 28, label: 28 });
      // for (let i = 1; i <= 28; i++) {
      //   c.push({ value: i, label: i });
      // }
      this.daysCollection = c;
    },
    async getUserAccounts() {
      try {
        const res = await axios.get(`api/v1/userBankAccounts/${this.UserId}`);
        const accs = res.data;
        this.userAccounts = accs;
        this.formattedAccounts = [];
        accs.forEach(f => {
          let nacc = this.getFormattedAccount(f);
          if (nacc.is_verified) {
            this.formattedAccounts.push(nacc);
          }
        });

        this.formattedAccounts.sort(function (a, b) {
          if (a.is_default) {
            return -1
          }
          else if (b.is_default) {
            return 1;
          }
          else if (a.is_verified && b.is_verified) {
            return a.clabe > b.clabe;
          }
          else if (a.is_verified && !b.is_verified) {
            return -1
          }
          else if (!a.is_verified && b.is_verified) {
            return 1
          }
          return 0;
        });
      }
      catch (e) {
      }
    },
    getFormattedAccount(f) {
      let nacc = {
        id: f.id,
        bank_id: f.bank_id,
        is_default: f.is_default === 1,
        is_verified: f.verified === BankAccountStatus.VERIFIED,
        status_name: BankAccountStatus.getAccountStatusName(f.verified),
        direct_billing_acceptance: f.bank.direct_billing_acceptance,
      };

      if (f.bank && f.bank.country_id === this.mexicoCountryId) {
        nacc.bank_name = f.bank.short_name;
        nacc.clabe = f.clabe;
      } else {
        nacc.bank_name = f.bank.name;
        nacc.clabe = f.bic_swift;
      }

      nacc.value = nacc.id;
      nacc.label = nacc.clabe;

      return nacc;
    },
    async getLatestInvestorDirectBilling() {
      try {
        const res = await axios.get(`api/v2/investor/${this.InvestorId}/getLatestInvestorDirectBilling`);
        this.latestInvestorDirectBilling = res.data;
        // pre-seleccionar cuenta
        if (this.latestInvestorDirectBilling?.bank_account_id != null) {
          this.processingDay = this.latestInvestorDirectBilling.processing_day;
          this.accountIdSelected = this.latestInvestorDirectBilling.bank_account_id;
          // this.accountRowSelected = this.formattedAccounts.find(f => f.id === this.latestInvestorDirectBilling.bank_account_id);
        } else {
          const d = new Date();
          let todayDay = d.getDate();
          if (todayDay >= 1 && todayDay <= 6) {
            this.processingDay = 7;
          } else if (todayDay >= 7 && todayDay <= 13) {
            this.processingDay = 14;
          } else if (todayDay >= 14 && todayDay <= 20) {
            this.processingDay = 21;
          } else if (todayDay >= 21 && todayDay <= 27) {
            this.processingDay = 28;
          } else {
            this.processingDay = 7;
          }
          // this.accountRowSelected = this.formattedAccounts[0];
          this.accountIdSelected = this.formattedAccounts[0].id;
        }
      }
      catch (e) {
      }
    },
    onAccountSelected(tr) {
      if (tr.id === this.accountIdSelected) {
        return;
      }
      else {
        this.accountIdSelected = tr.id
      }
    },
    isAccountSelected(tr) {
      return tr.id === this.accountIdSelected;
    },
    async getPlansInfo() {
      try {
        const res = await axios.get(`api/v2/investor/${this.InvestorId}/getInvestorPlansInfo`);
        this.investorPlan = res.data.plan;
        this.accountInvestmentMoney = this.currencyFormat(this.investorPlan.card_amount);
        this.activeBankClabe = res.data.bank_clabe_hidden_str;
        this.investorPlan.suspendedDirectBillingBankAccount = res.data.suspended_direct_billing_bank_account;
      }
      catch (e) {
      }
    },
    confirmSwitchInvestmentPlanOption(option, value) {
      let message = "";
      let title = "";
      this.switchPlanOption = option;
      this.switchPlanValue = value;
      // si se va a activar no se muestra mensaje de confirmacion
      if (value) {
        this.switchInvestmentPlanOption();
        return;
      }
      let firstText = value == 1 ? "reactivar" : "pausar";
      let secondText = value == 1 ? "reactivado" : "pausado";
      let thirdText = firstText == "reactivar" ? "pausar" : "reactivar";
      switch (option) {
        case "autoinvest_deposits":
          // this.$refs.rgGeolocator.askForGeolocation();
          title = "¿Estás seguro que deseas " + firstText + " el abono automático?";
          message = "El abono automático será " + secondText + ", lo puedes " + thirdText + " en cualquier momento.";
          break;
        case "autoinvest_applicant_payments":
          title = "¿Estás seguro que deseas " + firstText + " la inversión automática?";
          message = "El proceso de inversión automática será " + secondText + ", lo puedes " + thirdText + " en cualquier momento.";
          break;
        default:
          break;
      }
      this.successDialogWithCallbacks(
        this.switchInvestmentPlanOption,
        () => {
          if (option == "autoinvest_applicant_payments") {
            this.autoInvestPayments = !this.autoInvestPayments;
          } else if (option == "autoinvest_deposits") {
            this.directBillingisActive = true;
          }
        },
        title,
        message,
        "Confirmar",
        "Cancelar"
      );
    },
    async saveMonthlyAutoInvestPrefs() {
      this.showLoading(true);
      let amount = this.unMoneyFormat(this.accountInvestmentMoney);
      try {
        let payload = {
          bank_account_id: this.accountIdSelected,
          direct_billing_max_amount: amount,
          processing_day: this.processingDay,
          contractType: this.contractInvestorDirectBilling,
          geolocation: this.CurrentGeolocationData
        };
        this.injectAccountMetadataToPayload(payload);
        await axios.post(`api/v2/investor/${this.InvestorId}/setAutoInvestDepositsDirectBilling`, payload);
        this.popUpmonthlyAutoInvestActive = false;
        this.successNotifDialog('Operación exitosa', 'Se han actualizado exitosamente tus preferencias de abono automático.');
        await this.getLatestInvestorDirectBilling();
        await this.loadPrefs();
      }
      catch (e) {
        this.errorNotifDialog('Error en guardado', 'No ha sido posible actualizar la información, intente más tarde.');
      }
      this.showLoading(false)
    },
    async switchInvestmentPlanOption() {
      this.showLoading(true)
      try {
        let payload = {
          option: this.switchPlanOption,
          value: this.switchPlanValue,
          geolocation: null,
          contractType: null
        };
        // if (this.switchPlanOption == 'autoinvest_deposits' && !this.switchPlanValue) {
        //   payload.geolocation = this.CurrentGeolocationData;
        //   payload.contractType = this.contractCancelDirectBillingType;
        // }
        this.injectAccountMetadataToPayload(payload);
        await axios.post(`api/v2/investor/${this.InvestorId}/switchInvestmentPlanOption`, payload);
        this.showLoading(true);
        this.isMounted = false;
        await this.loadPrefs();
        //  this.factorEnabled = await this.getUser2FAStatus(this.UserId);
        this.isMounted = true;
        this.showLoading(false);
        this.successNotifDialog('Operación exitosa',
          'Se han actualizado exitosamente tus preferencias sobre el plan de inversión.');
      }
      catch (e) {
        const mssg = this.catchError(e);
        this.errorNotif('Error en la operación', mssg);
      }
      this.showLoading(false);
    },
    catchError(e) {
      if (e?.response?.data?.error) {
        return e.response.data.error
      }
      else {
        return "No ha sido posible actualizar la información";
      }
    },
    async setDefaults() {
      this.autoInvestPayments = this.investorPlan.autoinvest_applicant_payments === 1;
      this.monthlyAutoInvest = this.investorPlan.autoinvest_deposits === 1;
      this.directBillingisActive = this.investorPlan.suspendedDirectBillingBankAccount ? false : true;
    },
    loadedRequirements(count) {
      this.mainComplianceRequirements = count;
    },
    async onBack() {
      this.showNewAccountForm = false;
    },
    async onBankAccountSaved() {
      this.showNewAccountForm = false;
      this.showLoading(true);
      await this.getUserAccounts();
      await this.getLatestInvestorDirectBilling();
      this.showLoading(false);
    },
  }
}
</script>