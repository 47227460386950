<template>
  <div id="inicio" class="user-start">
    <!-- INICIO SIN ONBOARDING -->
    <template>
      <vx-card class="mt-5 mb-5">
        <div class="vx-row">
          <div class="vx-col w-full">
            <h1 class="rg-big-title extrabold">¡Bienvenid@ a la <span>Red</span>!</h1>
          </div>
        </div>
      </vx-card>

      <compliance-banner class="mb-6 text-align-left" :showBanner="!SessionLocationRegistered"/>

      <div v-if="supplierObject.restriction_level != 'no_restriction'" class="vx-row mb-2">
        <div class="vx-col w-full">
          <SupplierRestrictedDataNotification :source="supplierObject"/>
        </div>
      </div>
      
      <div v-if="!hasFinished" class="mb-5">
        <div class="vx-row justify-between">
          <div class="vx-col w-full mb-2">
            <vs-alert color="warning" active="true" style="height:auto">
              Tu perfil esta en <strong>proceso de verificación</strong>. Puedes crear una Pre-Solicitud para que RedGirasol evalúe su viabilidad crediticia. Solo considera que para concluir el proceso de evaluación de la Solicitud deberás tener tu perfil como Proveedor ya verificado por parte de RedGirasol.
              <br>
              Si tienes alguna duda, por favor comunícate al número <strong>{{phoneFormat("8115398784")}}</strong> y manda un mensaje de Whatsapp al mismo número.
              <br>
              <vs-button class="mt-2 mb-2" color="warning" @click.stop="goTo('SupplierOnboardingHomePage')">Completar perfil</vs-button>
              <vs-button @click.stop="goToHome()" color="primary" class="mr-3 ml-3">Envíar WhatsApp</vs-button>
              <vs-button @click.stop="goToCall()" color="dark" class="">Llamar al {{ phoneFormat("8115398784") }}</vs-button>
            </vs-alert>
          </div>
        </div>
      </div>

      <!-- SOLICITUDES DE CRÉDITO ASIGNADAS -->
      <!-- <div>
        <NewAssignedLoansRequest />
      </div> -->

      <!-- cards -->
      <div class="vx-row">
        <div class="vx-col sm:w-full sm:w-1/2 md:w-1/2 lg:w-1/3 mb-base">
          <vx-card class="gradient-border">
            <h1 class="mb-base white w-2/3 mt-base">
              Te prestamos hasta 10 MDP para tu negocio
            </h1>
            <vs-button @click.stop="$router.push('/proveedor/simulador-express?credit-type=3')" size="small" class="primary">Simular</vs-button>
          </vx-card>
        </div>
        <div class="vx-col sm:w-full sm:w-1/2 md:w-1/2 lg:w-1/3 mb-base">
          <vx-card class="second-card">
            <h1 class="mb-base white w-2/3 mt-base">
              Simula un Crédito Solar para tu cliente
            </h1>
            <vs-button @click.stop="goTo('simuladorExpress')" size="small" color="primary">Simular</vs-button>
          </vx-card>
        </div>
        <div class="vx-col sm:w-full sm:w-full md:w-full lg:w-1/3 mb-base">
          <!-- <div class="vx-row">
            <div class="vx-col w-full mb-4">
              <vx-card class="card-txt special-card p-1">
                <div class="flex justify-between">
                  <p class="w-2/3">Consulta las <strong>herramientas</strong> que tenemos para ti</p>
                  <vs-button @click.stop="goTo('misHerramientas')" size="small" type="border" class="dark">Consultar</vs-button>
                </div>
              </vx-card>
            </div>
            <div class="vx-col w-full mb-4">
              <vx-card class="card-txt special-card p-1">
                <div class="flex justify-between">
                  <p class="w-2/3 mr-1">Crea una <strong>solicitud de crédito</strong> para tu cliente</p>
                  <vs-button @click.stop="goTo('crearProyecto')" size="small" type="border" class="primary">Crear solicitud</vs-button>
                </div>
              </vx-card>
            </div>
            <div class="vx-col w-full mb-2">
              <vx-card class="card-txt special-card p-1">
                <div class="flex justify-between">
                  <p class="w-2/3 mr-1">Configura tu <strong>código de referencia</strong>.</p>
                  <vs-button @click.stop="goTo('perfil-cuenta')" size="small" type="border" class="primary">Configurar</vs-button>
                </div>
              </vx-card>
            </div>
          </div> -->
          <div class="vx-row">
            <div class="vx-col w-full relative">
              <vx-card class="p-base align-center">
                <div v-if="!SupplierCanUseReferalCode || !SessionLocationRegistered" class="overlay-disabled">
                  <h4 class="p-16">Por el momento no puedes promover los productos de RedGirasol.</h4>
                </div>
                <h3 class="mb-base">Cómo crear una solicitud</h3>
                <p class="mb-base">Comparte tu código a tus clientes y cuando terminen su solicitud, sabremos que vienen de tu parte</p>
                <div class="flex justify-between w-full">
                  <vs-button :disabled="!SupplierCanUseReferalCode || !SessionLocationRegistered" size="small" type="border" color="black" icon-pack="feather" icon="icon-download" @click.stop="downloadQRCode()">Descargar</vs-button>
                  <vs-button :disabled="!SupplierCanUseReferalCode || !SessionLocationRegistered" size="small" type="border" color="black" icon-pack="feather" icon="icon-share-2" @click.stop="myFunction()">Compartir</vs-button>
                </div>
              </vx-card>
            </div>
          </div>
        </div>
      </div>

      <!-- verificación de identidad -->
      <div v-if="showIdentityVerification" class="vx-row">
        <div class="vx-col sm:w-full md:w-2/3 mb-base">
          <vx-card title="Verificación de identidad" class="card-txt special-card">
            <div class="vx-row m-2">
              <supplier-identity-verification
                ref="supplier_identity_verification"
                :isMoral="isMoral"
                :userId="UserId"
                :personType="UserPersonType"
                :supplierId="SupplierId"
              />
            </div>
          </vx-card>
        </div>
      </div>

      <!-- RESUMEN DE PROYECTOS -->
      <div v-if="SupplierOrAgentHasAtLeastOneProject" class="vx-row">
        <div class="vx-col w-full h-auto">
          <projects-summary-widget />
        </div>
      </div>

      <!-- REPORTE DE AVANCE DEL PROGRAMA -->
      <div v-if="isProgramSupplier" class="mb-6">
        <progress-report-widget></progress-report-widget>
      </div>

    </template>
    <!-- INICIO SIN ONBOARDING -->
  </div>
</template>

<script>
import ProgressReportWidget from '../../components/supplier/widgets/ProgressReportWidget.vue';
import * as moment from "moment";
//  Registered Widgets
import '@/widgets.js'
import SupplierOnboardingHomePage from './onboarding/SupplierOnboardingHomePage.vue';
import SupplierIdentityVerification from '../../components/supplier/profile/SupplierIdentityVerification.vue';
import userIdentityHelper from "@/helpers/userIdentityHelper";
import formatHelper from "@mixins/formatHelper.js";
import NewAssignedLoansRequest from '../../components/supplier/widgets/NewAssignedLoansRequest.vue';
import SupplierRestrictedDataNotification from '@/views/supplier/homes/SupplierRestrictionLevelNotif.vue';
import ComplianceBanner from '@components/compliance/ComplianceBanner';

const lastStepPM = "pm_mex_20_merchant_contract";
const lastStepPF = "pf_mex_18_merchant_contract";

export default {
  mixins: [userIdentityHelper, formatHelper],
  components: {
    ProgressReportWidget,
    SupplierOnboardingHomePage,
    SupplierIdentityVerification,
    NewAssignedLoansRequest,
    SupplierRestrictedDataNotification,
    ComplianceBanner
  },
  data() {
    return {
      showIdentityVerification: false,
    }
  },
  async beforeMount(){
    if(!this.hasFinished && this.isAgent) {
      await this.$router.replace({ name: 'supplierAgentOnboarding' });
    }

    if(this.ShowSupplierOnboarding) {
      if (this.isAgent) {
        await this.$router.replace({ name: 'supplierAgentOnboarding' });
      } else {
        await this.$router.replace({ name: 'supplierOnboarding' });
      }
    }
  },
  async mounted() {
    const data = await this.getUserIdentityStatus(this.UserId);
    if (data == null || data == 4 || data == 5 || data == 0) {
      this.showIdentityVerification = true;
    }
  },
  computed: {
    isAgent() {
      return this.$acl.check('isAgent');
    },
    hasFinished() {
      return (this.SupplierOnboardingStep == lastStepPM || this.SupplierOnboardingStep == lastStepPF) && this.SupplierVerifiedDate != null
    },
    getLogoUrl: function () {
      return this.$data.StorageURL + "/green/LOGOS/redgirasol.png"
    },
    isProgramSupplier() {
      return this.SupplierOrAgentProgramAccess;
    },
    isMoral() {
      return this.UserPersonType == 0;
    },
    supplierObject() 
    {
      return {
        id: this.SupplierId,
        restriction_level: this.SupplierRestrictionLevel
      }
    },
    message() {
        return `Solicita tu Crédito Solar en RedGirasol *obtén financiamiento para tus paneles solares* para tu casa o negocio. Aprovecha la energía del sol y *ahorra hasta 95% de tu recibo de luz*.

Empieza tu registro aquí: ${this.supplierReferredUrl}`;
    },
    supplierReferredUrl() {
      return `${this.AppDomain}/registro/solicitante?r=${this.SupplierReferralCode}`;
    },
  },
  methods: {
    showFeedback() {
      this.$store.commit('TOGGLE_IS_FEEDBACK_POPUP_ACTIVE', true)
    },
    async goTo(route) {
      await this.$router.replace({name: route});
    },
    skip() {
      //this.setTourStatus(1,1);
    },
    finish() {
      //this.setTourStatus(2,1);
    },
    async goToHome() {
        window.open("https://wa.me/528115398784/?text=¡Hola, he terminado con el proceso para la apertura de mi cuenta!", "_blank")
    },
    async goToCall() {
        window.open("tel:8115398784")
    },
    async downloadQRCode() {
      try {
          const url = `${this.ApiDomain}/api/loan/get/${this.SupplierId}/qr-code-pdf-preview?access_token=${this.AccessToken}`;
          window.open(url, "_blank");
      } catch (error) {
          console.log(error);
      }
    },
    myFunction() {
      // Copy the text inside the text field
      navigator.clipboard.writeText(this.message);

      this.saveSuccessNotif(null, "Texto copiado correctamente.");
    },
  }
}
</script>
<style>
#preFooter {
  margin-bottom: -30px;
}

#feedbackAlert {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
  cursor: pointer;
  height: auto !important;
}

.v-tour__target--highlighted {
  box-shadow: 0 0 0 99999px rgba(0, 0, 0, .4);
}

.v-step {
  z-index: 100000 !important;
  background: #3b3a3e !important;
}

.v-step .v-step__header {
  background: #3b3a3e !important;
}

.big-title-bg {
  background-color: #28DE18 !important;
}

.big-title-bg h1 {
  color: #fff !important;
  font-size: 3rem !important;
}

.big-title-bg h1 span {
  color: #3b3a3e !important;
  font-size: 3rem !important;
}

.user-start .vx-row .vx-col:not(.h-auto) {
  min-height: 30px !important;
}

.user-start .vx-row .vx-col:not(.h-auto) {
  margin-bottom: 0rem;
}

.vx-card .vx-card__collapsible-content .vx-card__body {
  padding: 1rem !important;
}

.xmas-header {
  background-color: #2d2d2d;
  color: #fff;
  padding: 2rem;
}

.xmas-header p {
  font-size: 2.1rem;
  line-height: 2.2rem;
}

.xmas-header p>span {
  color: #28DE18;
}

.xmas-header img {
  margin-bottom: 1rem;
  width: 50%;
}

#notification .vs-popup--header {
  background: #28DE18 !important;
}

#notification .vs-popup--header .vs-popup--title h3 {
  color: #fff !important;
}

.gradient-border {
  position: relative;
  padding: 10px 20px;
  background: #fff;
  /* margin: 5px; */
  border-radius: 5px;
  background-image: url("https://cdn.redgirasol.com/suppliers/resources/mkt_pyme_loan.png");
  background-position: right;
  background-size: cover;

  /* border-image: linear-gradient(45deg, rgba(203,255,0,1) 0%, rgba(40,222,24,1) 100%) 1 !important; */
}

  .gradient-border::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;

    margin: -3px;
    border-radius: inherit;
    /* background-image:linear-gradient(45deg, rgba(203,255,0,1) 0%, rgba(40,222,24,1) 100%); */
  }

.special-card 
{
  border: 2px solid transparent !important;
  /* border: 1.5px solid #28de18; */
}

  .special-card:hover {
    border-image: linear-gradient(45deg, rgba(203,255,0,1) 0%, rgba(40,222,24,1) 100%) 1 !important;
  }

/* .first-card {
  padding: 3rem 3.5rem 2rem 3.5rem;
} */

.second-card {
  text-align: left;
  padding: 10px 20px;
  /* padding: 3rem 0.5rem 2rem 0.5rem; */
  background-image: url("https://cdn.redgirasol.com/suppliers/resources/mkt_personal_loan.png");
  /* background-position: right; */
  background-size: cover;
}

.card-txt {
  font-size: 18px;
  color: #3b3a3e;
}

.extrabold {
  font-size: 3rem;
}

span.primary {
  color: #28DE18 !important;
}

.overlay-disabled {
  background-color: #ffffffd1;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 1;
  backdrop-filter: blur(2px);
}
</style>
