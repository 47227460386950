<template>
  <div id="proveedor">
      <vx-card class="mb-6" >
        <div class="vx-row">
          <div class="vx-col w-full">
              <h1 class="rg-big-title extrabold rg-title">Mis <span class="orange">proyectos</span>.</h1>
          </div>
        </div>
      </vx-card>

      <compliance-banner class="mb-6"/>

    <vx-card>
      <template v-if="projectsCount > 0 && loaded">
        <div class="vx-row">
          <div class="vx-col w-full md:w-2/3" >
            <p>
              Aquí encontrarás toda la información de los proyectos que has creado,
              conocer el estado actual, y acceder al detalle de cada uno.
              También podrás crear una nueva solicitud dando click en el botón "Nuevo proyecto".
              Asímismo, puedes <strong>ver la lista de proyectos archivados</strong>.
            </p>
          </div> 
        </div>
      </template>
      <template v-else-if="loaded" >
        <h2>¡Aún no has creado ningún proyecto!</h2>
        <p>No esperes más y comienza a crear solicitudes de crédito para tus clientes. Da click en el siguiente botón para comenzar.</p>
      </template>

      <div class="vx-row mb-6 mt-4">
        <div class="vx-col w-1/2">
          <vs-button color="primary" type="border" icon-pack="feather" icon="icon-plus" @click="goToNewProject">Nuevo proyecto</vs-button>
        </div>
        <div class="vx-col w-1/2">
          <vs-button color="dark" type="border" icon-pack="feather" icon="icon-archive" @click="goToArchivedProjects">Ver proyectos archivados</vs-button>
        </div>
      </div>

      <projects-list @loadedProjects="loadedProjects" :status="status" ></projects-list>
    </vx-card>
  </div>
</template>

<script>
import ProjectsList from '../../../components/supplier/projects/ProjectsList.vue';
import ComplianceBanner from '@components/compliance/ComplianceBanner';
export default {
  components: {
    ProjectsList,
    ComplianceBanner
  },
  data() {
    return {
      projectsCount: 0,
      loaded: false,
      status: null,
    }
  },
  created(){
    this.status = this.$route.query.status;
  },
  methods: {
    loadedProjects(count) {
      this.projectsCount = count;
      this.loaded = true;
    },
    goToNewProject(){
      this.$router.replace({ name: 'crearProyecto'});
    },
    goToArchivedProjects(){
      this.$router.replace({ name: 'misProyectosArchivados'});
    }
  }
}
</script>