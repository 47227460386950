<template>
    <div id="contracts-page">
        <div class="vx-row">
            <div class="vx-col w-full mb-2">
                <h2>Firma de contratos</h2>
                <p>Procede a confirmar la información de la persona solicitante, obligados solidarios y firmar el contrato.</p>
                <vs-divider></vs-divider>
            </div>
            <div v-if="hasContracts" class="vx-col w-full">
                <vs-tabs v-if="isMounted" v-model="activeTab" alignment="left" color="primary">
                    <vs-tab :label="getContractName(contract)" v-for="(contract, index) in contractsArray" :key="index">
                        <div class="flex m-4 mb-0">
                            <feather-icon
                                icon="FileTextIcon"
                                svgClasses="w-5 h-5 mr-2"
                                class="cursor-pointer text-primary "
                            />
                            <p class="bold black ">Sobre el contrato</p>
                        </div>
                        <p class="ml-12">
                            Fecha de creación: {{ midDateFormat(contract.created_at) }} <br>
                            Estado: {{ contract.status_named }}
                        </p>
                        <vs-divider></vs-divider>
                        <div v-if="showSigners(contract)">
                            <h4 class="mt-8">Firmantes</h4>
                            <div v-if="contract.signing_type == MiFielType" class="vx-row">
                                <div v-if="signatory.type != 'redgirasol'" class="vx-col sm:w-full md:w-11/12 lg:w-1/2 signer-card" v-for="signatory in contract.signatories_json">
                                    <div class="vx-row">
                                        <div class="vx-col sm:w-full md:w-2/3 lg:w-2/3">
                                            <p>{{ getSignerType(signatory.type).toUpperCase() }}</p>
                                            <h4 class="bold black">{{ signatory.name }}</h4>
                                            <p>{{ signatory.email || "--" }}</p>
                                            <p>{{ signatory.tax_id || "--" }}</p>
                                            <p>{{ phoneFormat(signatory.phone) || "--" }}</p>
                                        </div>
                                        <div v-if="getMiFielSignerObj(contract.mifiel_object, signatory.tax_id).widget_id != null" class="vx-col sm:w-full md:w-1/3 lg:w-1/3 align-self-center">
                                            <div v-if="!getMiFielSignerObj(contract.mifiel_object, signatory.tax_id).signed" class="flex justify-evenly">
                                                <vs-button class="pt-2 pb-2" @click.stop="executeUrl(buildMiFielURL(contract.id, getMiFielSignerObj(contract.mifiel_object, signatory.tax_id).widget_id))">Firmar contrato</vs-button>
                                                <vs-button size="" type="border" icon="content_copy" class="p-2" @click.stop="copyToClipboard(buildMiFielURL(contract.id, getMiFielSignerObj(contract.mifiel_object, signatory.tax_id).widget_id))"></vs-button>
                                            </div>
                                            <div v-else class="flex justify-evenly">
                                                <vs-chip color="primary">
                                                    Firmada completada
                                                </vs-chip>
                                            </div>
                                        </div>
                                        <div class="vx-col sm:w-full md:w-1/3 lg:w-1/3 align-self-center" v-else>
                                            <small class="italic">
                                                <feather-icon
                                                    icon="AlertCircleIcon"
                                                    svgClasses="w-5 h-5 mt-1"
                                                    class="cursor-pointer text-primary "
                                                />
                                                Tu firma no fue generada correctamente, por favor contáctanos a través del chat para solucionar este problema.
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-else-if="contract.signing_type == FADType" class="vx-row">
                                <div v-if="signatory.type != 'redgirasol'" class="vx-col sm:w-full md:w-11/12 lg:w-1/2 signer-card" v-for="(signatory, index) in contract.signatories_json">
                                    <div class="vx-row">
                                        <div class="vx-col sm:w-full md:w-2/3 lg:w-2/3">
                                            <p>{{ getSignerType(signatory.type).toUpperCase() }}</p>
                                            <h4 class="bold black">{{ signatory.name }}</h4>
                                            <p>{{ signatory.email || "--" }}</p>
                                            <p>{{ signatory.tax_id || "--" }}</p>
                                            <p>{{ phoneFormat(signatory.phone) || "--" }}</p>
                                        </div>
                                        <div v-if="contract.fad_requisition != null && contract.fad_requisition != 'null'" class="vx-col sm:w-full md:w-1/3 lg:w-1/3 align-self-center">
                                            <div v-if="contract.status == 'generated' || contract.status == 'sent'" class="flex justify-evenly">
                                                <vs-button class="pt-2 pb-2" @click.stop="executeUrl(getFadLink(index, contract))">Firmar contrato</vs-button>
                                                <vs-button size="" type="border" icon="content_copy" class="p-2" @click.stop="copyToClipboard(getFadLink(index, contract))"></vs-button>
                                            </div>
                                            <div v-else class="flex justify-evenly">
                                                <vs-chip color="primary">
                                                    Firmada completada
                                                </vs-chip>
                                            </div>
                                        </div>
                                        <div class="vx-col sm:w-full md:w-1/3 lg:w-1/3 align-self-center" v-else>
                                            <small class="italic">
                                                <feather-icon
                                                    icon="AlertCircleIcon"
                                                    svgClasses="w-5 h-5 mt-1"
                                                    class="cursor-pointer text-primary "
                                                />
                                                Tu firma no fue generada correctamente, por favor contáctanos a través del chat para solucionar este problema.
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-else>
                                <div v-if="signatory.type != 'redgirasol'" class="vx-col sm:w-full md:w-11/12 lg:w-1/2 signer-card" v-for="(signatory, index) in contract.signatories_json">
                                    <div class="vx-row">
                                        <div class="vx-col sm:w-full md:w-2/3 lg:w-2/3">
                                            <p>{{ getSignerType(signatory.type).toUpperCase() }}</p>
                                            <h4 class="bold black">{{ signatory.name }}</h4>
                                            <p>{{ signatory.email || "--" }}</p>
                                            <p>{{ signatory.tax_id || "--" }}</p>
                                            <p>{{ phoneFormat(signatory.phone) || "--" }}</p>
                                        </div>
                                        <div class="vx-col sm:w-full md:w-1/3 lg:w-1/3 align-self-center">
                                            <div v-if="contract.status == 'generated' || contract.status == 'sent'" class="flex justify-evenly">
                                                <vs-chip color="warning">
                                                    Firma pendiente
                                                </vs-chip>
                                            </div>
                                            <div v-else class="flex justify-evenly">
                                                <vs-chip color="primary">
                                                    Firmada completada
                                                </vs-chip>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-else>
                            <div class="mb-2 address-card">
                                <h5 class="mb-2">
                                    Tus contratos no han sido enviados a firma.
                                </h5>
                                <p>
                                    Estamos trabajando para que tengas lo antes posible tus contratos. Cuando estén listos 
                                    te enviaremos a tu correo las intrucciones para que puedas firmarlos.
                                </p>
                            </div>
                        </div>
                    </vs-tab>
                </vs-tabs>
                <!-- <vs-list v-for="(contract, index) in contractsArray">
                    <vs-list-header color="black" :title="`${getContractName(contract)} -  Lista de firmantes - ${getSigningFormTitle(contract.signing_type)}`"></vs-list-header>
                    <div class="vx-row mb-base mt-4">
                        <div class="vx-col sm:w-full md:w-1/3" v-if="signatory.type != 'redgirasol'" v-for="signatory in contract.signatories_json">
                            <p class="bold black">{{ signatory.name }}</p>
                            <ul class="ul-signers black mb-2">
                                <li><span class="bold">Tipo: </span>{{ getSignerType(signatory.type) }}</li>
                                <li><span class="bold">Email: </span>{{ signatory.email || "--"}}</li>
                                <li><span class="bold">RFC: </span>{{ signatory.tax_id || "--" }}</li>
                                <li><span class="bold">Tel: </span>{{ signatory.phone || "--" }}</li>
                            </ul>
                            <div v-if="contract.mifiel_object != null && contract.signing_type == 2">
                                <h6>URL de firma:</h6>
                                <a :href="buildMiFielURL(contract.id, getMiFielWidgetId(contract.mifiel_object, signatory.tax_id))" target="_blank">{{ buildMiFielURL(contract.id, getMiFielWidgetId(contract.mifiel_object, signatory.tax_id)) }}</a>
                            </div>
                        </div>
                        <div v-if="contract.fad_requisition != null && contract.signing_type == 3" class="ml-2">
                            <div class="mt-2 ml-2">
                                <h6 class="mb-2">Links para firmar:</h6>
                                <ul v-if="link.type != 'Firma Proveedor'" class="ul-signers black mb-2 ml-base" v-for="(link, index) in buildFADLinks(contract)">
                                    <li><span class="bold">Tipo:</span> {{ link.type }}</li>
                                    <li><a :href="FADLinkBase + link.ticket" target="_blank"> {{ FADLinkBase + link.ticket }}</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </vs-list> -->
            </div>
            <div v-else class="vx-col w-2/3">
                <div class="mb-2 address-card">
                    <h5 class="mb-2">
                        Tus contratos no han sido generados.
                    </h5>
                    <p>
                        Estamos trabajando para que tengas lo antes posible tus contratos. Cuando estén listos 
                        te enviaremos a tu correo las intrucciones para que puedas firmarlos.
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import formatHelper from "@mixins/formatHelper";
import dateHelper from "@mixins/dateHelper";
const FADLinkBaseDevelopment = "https://uatwebfad.firmaautografa.com/signList?ticketReq=";
const FADLinkBaseProduction = "https://mobile.firmaautografa.com/main?ticket=";
const tutorialFADLink = "https://youtu.be/19npgHmTUi0";

const MiFielType = 2;
const FADType = 3;

export default {
    name: "ContractsPage",
    props: ["onboardingStepData", "isMoral", "isFromSupplier"],
    mixins: [formatHelper, dateHelper],
    data: () => ({
        activeTab: 0,
        isMounted: false,
        contractsArray: [],
        tutorialFADLink: tutorialFADLink,
        FADLinkBase: process.env.VUE_APP_ENV == "production" ? FADLinkBaseProduction : FADLinkBaseDevelopment,
        MiFielType: MiFielType,
        FADType: FADType
    }),
    mounted() {
        this.setDocuments();
    },
    computed: {
        base() {
            return this.onboardingStepData;
        },
        finance() {
            return this.base.finance;
        },
        isEcotechnologiesLoan() {
            return this.base.finance.loanType == 1;
        },
        contracts() {
            return this.base.contracts;
        },
        business() {
            return this.base.client.user.business;
        },
        personal() {
            return this.business.personal;
        },
        hasContracts() {
            return this.contracts != null && this.contracts.length != 0
        }
    },
    methods: {
        setDocuments() {
            this.contractsArray.push(this.contracts[0]); // Grupo 1

            // Buscamos al pagaré
            const pagare = this.contracts.find(x => x.type.name == "Pagaré");
            if(pagare != null) {
                this.contractsArray.push(pagare);
            }

            // Buscamos al grupo 2
            const groupTwo = this.contracts.find(x => x.contract_group_id == 2);
            if(groupTwo != null) {
                this.contractsArray.push(groupTwo);
            }

            // Buscamos al grupo 3
            const groupThree = this.contracts.find(x => x.contract_group_id == 3);
            if(groupThree != null) {
                this.contractsArray.push(groupThree);
            }

            // Buscamos al grupo 4
            const groupFour = this.contracts.find(x => x.contract_group_id == 4);
            if(groupFour != null) {
                this.contractsArray.push(groupFour);
            }

            this.isMounted = true;

        },
        getContractName(contract) {
            if(contract.type.name == "Pagaré") {
                return contract.type.name;
            } else {
                return `Grupo ${contract.contract_group_id}`
            }
        },
        getSignerType(type) {
            const list = {
                "owner"         : "Representante legal"
                , "customer"    : "Solicitante"
                , "supplier"    : "Proveedor"
                , "ob"          : "Obligado solidario"
            }

            return list[type]||"NA";
        },
        getSigningFormTitle(type) {
            const list = {
                "3"   : "FAD Firma Autógrafa Digital"
              , "2"   : "FIEL Firma Electrónica Avanzada"
            }

            return list[type]||"NA";
        },
        getSignersFromMiFiel(obj) {
            return JSON.parse(obj).signers;
        },
        getMiFielSignerObj(obj, rfc) {
            const signers = this.getSignersFromMiFiel(obj);
            const signer = signers.find(s => s.tax_id == rfc);
            if(signer) {
                return signer;
            } else {
                return {
                    signed: false,
                    widget_id: null
                };
            }
        },
        buildMiFielURL(contractId, widgetId) {
            return `${this.AppDomain}/firma-de-contratos?reqId=${contractId}&ticket=${widgetId}`;
        },
        buildMiFielLinks(miFielObject) {
            let links = [];
            if (miFielObject != null) {
                const data = JSON.parse(miFielObject);
                links = data.signers;
            }
            return links;
        },
        buildFADLinks(contract){
            let groupId = contract.contract_group_id;
            if (contract.fad_requisition != null){
                let requisition = JSON.parse(contract.fad_requisition);
                let links = [];
        
                let index = 0;
                let allTickets = Object.values(requisition.allTickets);
        
                allTickets.forEach(ticket => {
                let link = {};
                if(index == 0){
                    link.type =  "Firma Solicitante";
                } else {
                    let name = "";
                    if(groupId == 1){
                    name = "Firma Obligado Solidario";
                    } else if (groupId == 2 || groupId == 4){
                    name = "Firma Proveedor";
                    } else {
                    name = "N/A";
                    }
                    link.type = name;
                }
                link.ticket = ticket;
                links.push(link);
                index++;
                });
                return links;
            } else {
                return [];
            }
        },
        getFadLink(position, contract){
            const links = this.buildFADLinks(contract);
            if(links.length > 0){
                return `${this.FADLinkBase}${links[position].ticket}`;
            }
        },
        copyToClipboard(ticket){
            const el = document.createElement('textarea');
            el.value = ticket;
            el.setAttribute('readonly', '');
            el.style.position = 'absolute';
            el.style.left = '-9999px';
            document.body.appendChild(el);
            el.select();
            document.execCommand('copy');
            document.body.removeChild(el);
            this.successNotif("Liga copiada al portapapeles");
        },
        executeUrl(url) {
            window.open(url, '_blank');
        },
        showSigners(contract) {
            if(contract.status == "verified" || contract.status == "sent" || contract.status == "signed") {
                return true;
            } else {
                return false;
            }
        }
    }
}
</script>

<style>

.ul-signers {
    list-style: disc;
    margin-left: 1rem;
}

.signer-card {
    border: 1px solid #c2c2c2;
    border-radius: 5px;
    margin: 1rem;
    padding: 1rem !important;
}

</style>